import request from '@/utils/request'


// 查询会员订单列表
export function listOrder(query) {
  return request({
    url: '/vip/vipOrders/list',
    method: 'get',
    params: query
  })
}

// 查询会员订单分页
export function pageOrder(query) {
  return request({
    url: '/vip/vipOrders/page',
    method: 'get',
    params: query
  })
}

// 查询会员订单详细
export function getOrder(data) {
  return request({
    url: '/vip/vipOrders/detail',
    method: 'get',
    params: data
  })
}

// 新增会员订单
export function addOrder(data) {
  return request({
    url: '/vip/vipOrders/add',
    method: 'post',
    data: data
  })
}

// 修改会员订单
export function updateOrder(data) {
  return request({
    url: '/vip/vipOrders/edit',
    method: 'post',
    data: data
  })
}

// 删除会员订单
export function delOrder(data) {
  return request({
    url: '/vip/vipOrders/delete',
    method: 'post',
    data: data
  })
}
